  .commonLoad {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1001;
    background: rgba(252, 252, 253, 0.78);
  }
  .commonLoad .img{
    height: 100px;
  }