body {
  margin: 0;
  font-family: 'Roboto Condensed', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.container_style{
  margin: 0px auto;
  max-width: 1300px;
  width: 100%;
}
.container_style_2{
  margin: 0px auto;
  max-width: 1450px;
  width: 100%;
}
@media (max-width:1600px) {
  .container_style{
    max-width: 1180px;
  }
  .container_style_2{
    max-width: 1450px;
  }
}
@media (max-width:1500px) {
  .container_style_2{
    padding: 32px 32px 0px 32px
  }
}
@media (max-width:1212px) {
    .container_style{
        padding: 0px 32px;
    }
    .container_style_2{
        padding: 0px 32px;
    }
}
@media (max-width:899.5px) {
    .container_style{
        padding: 0px 16px;
    }
    .container_style_2{
        padding: 0px 16px;
    }
}